<template>
  <div class="page-content">
    <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
      <!-- panel-body begin -->
      <div class="panel-body">
        <h1 class="page-header">用户管理 <small>(添加系统用户)</small></h1>
        <vxe-toolbar ref="xToolbar" :loading="loading" custom zoom print>
          <template #buttons>
            <vxe-input
              style="width: 250px"
              size="mini"
              v-model="CompanyUserName"
              type="text"
              @keyup.enter.native="CompanyUserIdkeyupEvent"
              placeholder="用户名称查询"
            >
            </vxe-input>
            <vxe-button
              size="mini"
              circle
              icon="fa fa-search"
              @click="CompanyUserIdkeyupEvent"
            ></vxe-button>
            <vxe-button
              size="mini"
              icon="fa fa-plus"
              status="primary"
              content="新增"
              @click="insertEvent"
            ></vxe-button>
            <div class="btn-group btn-group-justified m-l-20 m-r-20">
              <a
                v-bind:class="
                  admin ? 'btn btn-default active' : 'btn btn-default'
                "
                v-on:click="Roleselect(0)"
                >管理员</a
              >
              <a
                v-bind:class="
                  companyUser ? 'btn btn-default active' : 'btn btn-default'
                "
                v-on:click="Roleselect(1)"
                >企业用户</a
              >
              <a
                v-bind:class="
                  gridhead ? 'btn btn-default active' : 'btn btn-default'
                "
                v-on:click="Roleselect(2)"
                >网格长</a
              >
            </div>
            <vxe-button icon="fa fa-download" size="mini" @click="getAlldata(0)"
              >导出</vxe-button
            >
          </template>
        </vxe-toolbar>
        <vxe-table
          round
          border
          :height="tabelHeight"
          :loading="loading"
          :data="tableData"
          :print-config="{}"
          :export-config="{}"
          ref="xTable"
          :column-config="{ resizable: true }"
        >
          <vxe-column field="userName" title="账号" width="150" align="center">
            <template #default="{ row }">
              <a href="#" @click="editEvent(row)"> {{ row.userName }} </a>
            </template>
          </vxe-column>
          <vxe-column field="name" title="姓名" width="300"></vxe-column>

          <vxe-column
            field="businessName"
            title="企业名称"
            min-width="200"
          ></vxe-column>
          <vxe-column
            field="phoneNumber"
            title="电话号码"
            width="150"
          ></vxe-column>
          <vxe-column
            field="roleName"
            title="角色名称"
            width="200"
          ></vxe-column>
          <vxe-column title="操作" fixed="right" width="340" align="center">
            <template #default="{ row }">
              <vxe-button
                size="small"
                class="m-r-5"
                icon="fa fa-user-circle"
                status="primary"
                @click="editEvent(row)"
                >账号密码
              </vxe-button>
              <vxe-button
                size="small"
                class="m-r-5"
                icon="fa fa-wrench"
                status="primary"
                @click="editEvent(row)"
                >编辑
              </vxe-button>
              <vxe-button
                size="small"
                status="warning"
                icon="fa fa-trash-alt"
                @click="removeRowEvent(row)"
                >删除</vxe-button
              >
            </template>
          </vxe-column>
        </vxe-table>
        <vxe-pager
          border
          icon-prev-page="fa fa-angle-left"
          icon-jump-prev="fa fa-angle-double-left"
          icon-jump-next="fa fa-angle-double-right"
          icon-next-page="fa fa-angle-right"
          icon-jump-more="fa fa-ellipsis-h"
          :loading="loading"
          :current-page="tablePage.currentPage"
          :page-size="tablePage.pageSize"
          :total="tablePage.totalResult"
          :layouts="[
            'PrevPage',
            'JumpNumber',
            'NextPage',
            'FullJump',
            'Sizes',
            'Total',
          ]"
          @page-change="handlePageChange"
        >
        </vxe-pager>
      </div>
    </div>
  </div>
</template>
<script>
import { businessuserApi } from "@/api/system/businessuserApi";
import CompanyUser from "./CompanyUser.vue";
export default {
  data() {
    return {
      CompanyUserName: "",
      tabelHeight: document.body.clientHeight - 300,
      admin: false,
      companyUser: false,
      gridhead: false,
      loading: false,
      tableData: [],
      tablePage: {
        currentPage: 1,
        pageSize: 10,
        totalResult: 0,
      },
      list: [],
    };
  },
  watch: {
    $route(to) {
      if (to.path == "/CompanyUserList") {
        this.GetCompanyUserList();
      }
    },
  },
  created() {},
  mounted() {
    let that = this;
    window.onresize = () => {
      return (() => {
        that.tabelHeight = document.body.clientHeight - 300;
      })();
    };
    that.GetCompanyUserList();
  },

  methods: {
    //查询事件用于列表查询
    CompanyUserIdkeyupEvent() {
      this.tablePage.currentPage = 1;
      this.admin = false;
      this.companyUser = false;
      this.gridhead = false;
      this.GetCompanyUserList();
    },
    ToastSuccess(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "Success") {
        title = "删除成功";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "success",
        title: title,
      });
    },
    //进入企业编辑页面
    editEvent(row) {
      this.$modal.show(
        CompanyUser,
        { CompanyUserid: row.id },
        { height: "auto", width: "40%", draggable: true },
        {
          "before-close": this.beforeClose, //跳出框关闭是返回事件
        }
      ); //跳出框参数);
    },
    // 跳出窗关闭后返回事件
    beforeClose(event) {
      // if (this.admin == true) {
      //   this.GetCompanyUserList(0);
      // } else if (this.companyUser == true) {
      //   this.GetCompanyUserList(1);
      // } else if (this.gridhead == true) {
      //   this.GetCompanyUserList(2);
      //   this.gridhead == true;
      // }
      this.admin = false;
      this.companyUser = false;
      this.gridhead = false;
      this.GetCompanyUserList();
      console.log("before-close", event);
    },
    //获取企业列表数据
    GetCompanyUserList(RoleId) {
      let that = this;
      that.loading = true;
      businessuserApi
        .GetCompanyUserList({
          // 分页入参
          SkipCount: (that.tablePage.currentPage - 1) * that.tablePage.pageSize, //起始页数
          MaxResultCount: that.tablePage.pageSize, //每页几条
          Sorting: "", //排序字段
          Filter: that.CompanyUserName.replace(/^\s+|\s+$/g, ""), //名称模糊搜索(名称两头去空格)
          RoleId: RoleId,
        })
        .then((res) => that.GetSuccess(that, res))
        .finally(() => {
          that.loading = false;
        });
    },
    getAlldata(i) {
      let that = this;
      businessuserApi
        .GetCompanyUserList({
          // 分页入参
          SkipCount: i * 1000,
          MaxResultCount: 1000,
          Sorting: "", //排序字段
          Filter: that.CompanyUserName.replace(/^\s+|\s+$/g, ""), //名称模糊搜索(名称两头去空格)
          RoleId: 1,
        })
        .then((res) => {
          that.list.push(res.data.items);
          that.list = that.list.flatten();
          if (that.list.length < res.data.totalCount) {
            that.getAlldata(i + 1);
          }
          if (res.data.totalCount == that.list.length) {
            that.exportDataEvent();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 表格导出数据方法
    // exportDataEvent() {
    //   const $table = this.$refs.xTable.value;
    //   // this.$refs.xTable.openExport({ types: ["xlsx"] });
    //   $table.openExport();
    // },
    exportDataEvent() {
      this.loading = true;
      setTimeout(() => {
        let list = this.list;
        const $table = this.$refs.xTable;
        $table.openExport({
          types: ["csv", "xml", "txt"],
          modes: ["all"],
          filename: "沿街店铺数据导出",
          type: "csv",
          columnFilterMethod({ column }) {
            return [
              "businessName",
              "name",
              "phoneNumber",
              "roleName",
              "userName",
            ].includes(column.field);
          },
          isHeader: true,
          isFooter: true,
          data: list,
        });
        // $table.exportData({
        //   filename: "用户列表数据导出",
        //   type: "csv",
        //   isHeader: true,
        //   isFooter: true,
        //   data: list,
        // });
        this.list = [];
        this.loading = false;
      }, 100);
    },

    // 获取成功
    GetSuccess(that, res) {
      if (res.success) {
        that.tableData = res.data.items;
        that.tablePage.totalResult = res.data.totalCount;
      } else {
        console.log(res);
      }
    },
    //
    removeRowEvent(row) {
      // 删除提示框
      this.$swal({
        // title: "Are you sure?",
        text: "是否确定删除?",
        type: "info",
        icon: "warning",
        showCancelButton: true, //确定按钮
        buttonsStyling: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btn m-r-5 btn-" + "primary" + "", //按钮颜色设置
        cancelButtonClass: "btn btn-default", //按钮类型设置
      }).then((isConfirm) => {
        try {
          //判断 是否 点击的 确定按钮
          if (isConfirm.value) {
            let that = this;
            if (row.id) {
              businessuserApi
                .DeleteCompanyUser({ id: row.id })
                .then((res) => that.DeleteCompanyUserSuccess(that, res, row))
                .finally(() => {});
            }
          } else {
            console.log(isConfirm);
          }
        } catch (e) {
          alert(e);
        }
      });
    },
    DeleteCompanyUserSuccess(that, res, row) {
      if (res.success) {
        // Api请求成功之后前端页面删除该行
        const $table = this.$refs.xTable;
        $table.remove(row);
        this.ToastSuccess(res);
      } else {
        console.log(res);
      }
    },
    handlePageChange({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage;
      this.tablePage.pageSize = pageSize;
      this.GetCompanyUserList();
      // this.findList();
    },
    // 进入企业新增页面
    async insertEvent() {
      this.$modal.show(
        CompanyUser,
        { CompanyUserid: "" },
        { height: "auto", width: "40%", draggable: true },
        {
          "before-close": this.beforeClose, //跳出框关闭是返回事件
        }
      ); //跳出框参数);
    },
    Roleselect(RoleId) {
      this.admin = false;
      this.companyUser = false;
      this.gridhead = false;
      if (RoleId == 0) {
        this.GetCompanyUserList(0);
        this.admin = true;
      } else if (RoleId == 1) {
        this.GetCompanyUserList(1);
        this.companyUser = true;
      } else if (RoleId == 2) {
        this.GetCompanyUserList(2);
        this.gridhead = true;
      }
    },
  },
};
</script>
